import '@assets/scss/pages/project.scss';

import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

// components
import Layout from '@src/layouts';
import Video from '@components/atoms/Video';
import Blockquote from '@components/molecules/Blockquote';
// import ButtonExternal from '@components/atoms/buttons/ButtonExternal';
import Column from '@components/organisms/Column';
// import Cta from '@components/molecules/Cta';
import Gallery from '@components/organisms/Gallery';
import HeroProject from '@components/molecules/heros/HeroProject';
import Image from '@components/atoms/Image';
// import ItemArticle from '@components/molecules/items/ItemArticle';
import ListText from '@components/organisms/ListText';

// --------------------------------
// #region data
// --------------------------------

const language = 'en';

const pageClass = 'page-project';

const accent_color = '#FF5C39';
const footerRelatedLinks = [
	{
		title: 'Montreux Jazz Festival',
		url: '/en/projects/montreux-jazz-festival/',
	},
];

// --------------------------------
// #endregion
// --------------------------------

const ProjectAckTypoPage = (props) => {
	const data = useStaticQuery(
		graphql`
			query {
				heroImage: file(
					relativePath: { eq: "projects/etvj/img-ETVJ-cover.jpg" }
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				heroImageMobile: file(
					relativePath: {
						eq: "projects/etvj/img-ETVJ-cover-mobile.jpg"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_1: file(
					relativePath: { eq: "projects/etvj/img-ETVJ-ADN-EN.png" }
				) {
					childImageSharp {
						fluid(maxWidth: 1000, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_2: file(
					relativePath: { eq: "projects/etvj/img-ETVJ-logo.png" }
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_3: file(
					relativePath: {
						eq: "projects/etvj/img-ETVJ-pattern-EN.jpg"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_4: file(
					relativePath: { eq: "projects/etvj/img-ETVJ-poster.jpg" }
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_5: file(
					relativePath: { eq: "projects/etvj/img-ETVJ-memento-1.jpg" }
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_6: file(
					relativePath: { eq: "projects/etvj/img-ETVJ-memento-2.jpg" }
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_7: file(
					relativePath: { eq: "projects/etvj/img-ETVJ-memento-3.jpg" }
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_8: file(
					relativePath: {
						eq: "projects/etvj/img-ETVJ-pochette-fiche.jpg"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_9: file(
					relativePath: { eq: "projects/etvj/img-ETVJ-roll-up-1.jpg" }
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_10: file(
					relativePath: { eq: "projects/etvj/img-ETVJ-roll-up-2.jpg" }
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_11: file(
					relativePath: { eq: "projects/etvj/img-ETVJ-photo-1.jpg" }
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
			}
		`
	);

	return (
		<Layout
			language={language}
			pageClass={pageClass}
			footerRelatedLinks={footerRelatedLinks}
			accentColor={accent_color}
			location={props.location}
			translations={[
				{
					uri: '/fr/projets/ecole-technique-de-la-vallee-de-joux/',
					lang: 'fr',
				},
			]}
			title="Ecole Technique de la Vallée de Joux - By Superhuit"
		>
			<HeroProject
				breadcrumb={{
					title: 'Work',
					link: '/en/projects/',
				}}
				title="Ecole Technique de la Vallée de Joux"
				image={{
					...data.heroImage.childImageSharp,
				}}
				imageMobile={{
					...data.heroImageMobile.childImageSharp,
				}}
			/>
			<section className="grid grid-content" aria-label="Project">
				<div className="project-intro" data-animation-page>
					<div className="project-summary text-content">
						<p className="p-medium">
							Promoting the technicality and the emblematic
							heritage of the training courses in the
							Vallée-de-Joux.
						</p>

						<ListText
							className="element-spacing"
							title="Our services"
							list={[
								'Brand Workshop',
								'Branding',
								'Visual identity',
							]}
						/>
					</div>
					<div className="project-description text-content">
						<h3>The context</h3>
						<p>
							After years of having an identity that no longer fit
							with the reality of the Vallée de Joux Technical
							School, the time came to reposition the brand. By
							defining a strong visual identity, we aimed to
							highlight the attractiveness of the technical center
							in this magnificent, albeit remote, region.
						</p>

						<h3>Our response</h3>
						<p>
							Set strong visual markers, but also remain
							respectful to the historical character of the
							watchmaking professions and know-how. We wanted to
							lay the foundations of a pragmatic identity through
							its simplicity–seeking to give it an emblematic
							character–but also its dynamism, which highlights
							the attractiveness of the training courses.
						</p>
					</div>
				</div>

				<div className="box" data-animation-page>
					<div className="list-two-columns align-items-center justify-normal">
						<div className="text-content">
							<h3>The brand's DNA</h3>
							<p>
								Inspire youth while being realistic and
								pragmatic in order to transmit its personality
								and know-how. Herein lies the challenge of the
								ETVJ's visual reproduction.
							</p>
						</div>
						<Image
							{...data.image_1.childImageSharp}
							alt="The brand's DNA"
						/>
					</div>
				</div>

				<Column modifiers="right">
					<div className="column__text text-content">
						<h3>A minimalist and Swiss signature </h3>
						<p>
							The brand wants to bolster its heritage of Swiss
							quality and watchmaking know-how that it has
							embodied since 1901. The logo is simple and elegant,
							based on the timelessness of the Helvetic lineal.
						</p>
						<p>
							The minimalist style of the logo allows the visual
							identity to express itself with a personality that
							represents both the professions and the students.
						</p>
					</div>

					<div className="column__media text-content">
						<Image {...data.image_2.childImageSharp} alt="Logo" />
					</div>
				</Column>

				<Column modifiers="right">
					<div className="column__text column__sticky text-content">
						<h3>A technical and aesthetic visual glossary </h3>
						<p>
							The visuals are both descriptive and symbolic of the
							brand identity. They become strong and distinctive
							markers of the visual identity, expressing the
							meticulousness and aesthetic sensitivity of the
							professions.
						</p>
					</div>

					<div className="column__media text-content">
						<Image
							{...data.image_3.childImageSharp}
							alt="Graphic elements"
						/>
						<Video
							src="708ad301ca2bdc560bad5094decf680c"
							alt=""
							className="grid-spacing"
						/>
					</div>
				</Column>

				<Image
					className="col-full"
					{...data.image_4.childImageSharp}
					alt="Posters"
				/>

				<Gallery className="col-full grid-spacing" modifiers="gutter">
					<Image {...data.image_5.childImageSharp} alt="Memento" />
					<Image {...data.image_6.childImageSharp} alt="Memento" />
				</Gallery>

				<Image
					className="col-full grid-spacing"
					{...data.image_7.childImageSharp}
					alt="Memento"
				/>

				<Blockquote
					modifiers="big"
					uptitle=""
					quote="Aesthetics and technicality combined to offer the best of the local know-how."
					more="The visuals give pride of place to the space and they are associated with shapes of great finesse to express the technical mastery that lies behind the creativity."
					cite=""
				/>

				<Image
					className="col-full"
					{...data.image_8.childImageSharp}
					alt="Envelope and records"
				/>

				<Gallery className="col-full grid-spacing" modifiers="gutter">
					<Image {...data.image_9.childImageSharp} alt="Roll up" />
					<Image {...data.image_10.childImageSharp} alt="Roll up" />
				</Gallery>

				<Image
					className="col-full grid-spacing"
					{...data.image_11.childImageSharp}
					alt="P"
				/>
			</section>
		</Layout>
	);
};

export default ProjectAckTypoPage;
